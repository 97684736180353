<script setup>
import {computed, onMounted, ref, watch} from 'vue'
import {useStore} from 'vuex'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'
import { API, HOST } from '@/utils/http'

import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/toast'
import { Pencil } from 'lucide-vue-next'

const store = useStore()

const isOpen = ref(false)
const orgs = computed(() => store.getters['org/getOrgs'])

const close = () => {
  isOpen.value = false
}

const selectedOrgId = ref(null)
const selectedOrg = computed(() => {
  return orgs.value.find((o) => o.organization_id === parseInt(selectedOrgId.value))
})

const formSchema = ref(z.object({
  organization_id: z.string(),
  projects_limit: z.number().int().min(20),
  members_limit: z.number().int().min(20),
}))

const onSubmit = async (values) => {
  const response = await API({
    method: 'PUT',
    url: `${HOST}/organization/${values.organization_id}/limits`,
    data: {
      organization_id: values.organization_id,
      projects_limit: values.projects_limit,
      members_limit: values.members_limit,
    }
  })
}

onMounted(async () => {
  await store.dispatch('org/fetchOrgs')
})

watch(() => selectedOrgId.value, (value) => {
  formSchema.value = formSchema.value.extend({
    projects_limit: z.number().int().default(selectedOrg.value.projects_limit),
    members_limit: z.number().int().default(selectedOrg.value.members_limit),
  })
})
</script>

<template>
  <Dialog :open="isOpen" @update:open="isOpen = $event">
    <DialogTrigger>
      <slot>
        <Button size="sm" variant="secondary">
          <Pencil class="text-gray-500 w-4 h-4 mr-1" />
          Edit Organization Limits
        </Button>
      </slot>
    </DialogTrigger>
    <DialogContent class="max-w-[350px]">
      <DialogHeader>
        <DialogTitle>Edit Organization Limits</DialogTitle>
        <DialogDescription></DialogDescription>
      </DialogHeader>

      <Form class="space-y-3" :validation-schema="toTypedSchema(formSchema)" @submit="onSubmit" :key="selectedOrgId">
        <FormField v-slot="{ value, componentField }" name="organization_id" v-model="selectedOrgId">
          <FormItem>
            <FormLabel>Organization</FormLabel>
            <FormControl>
              <Select v-bind="componentField">
                <SelectTrigger>
                  <SelectValue placeholder="Select a organization" />
                </SelectTrigger>
                <SelectContent class="max-h-[300px]">
                  <SelectGroup>
                    <SelectItem
                      v-for="(o, index) of orgs"
                      :key="index"
                      :value="`${o.organization_id}`"
                    >
                      {{o.organization_name}}
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="projects_limit">
          <FormItem>
            <FormLabel>Projects Limit</FormLabel>
            <FormControl>
              <Input v-bind="componentField" type="number" :disabled="!selectedOrgId" />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="members_limit">
          <FormItem>
            <FormLabel>Members Limit</FormLabel>
            <FormControl>
              <Input v-bind="componentField" type="number" :disabled="!selectedOrgId" />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <DialogFooter>
          <Button variant="ghost" @click="close" type="button">Close</Button>
          <Button type="submit">Save</Button>
        </DialogFooter>
      </Form>
    </DialogContent>
  </Dialog>
</template>
