<script setup lang="ts">
import { h, computed } from 'vue'
import { useStore } from 'vuex'
import type { ColumnDef } from '@tanstack/vue-table'
import {formatTime} from '@/utils/datetime.js'

import { Invite } from '@/types'
import CopyButton  from './CopyButton.vue'
import { ArrowUpDown, ChevronDown } from 'lucide-vue-next'
import { Button } from '@/components/ui/button'
import { DataTable } from '@/components/ui/data-table'

const store = useStore()
const settings = computed(() => store.getters['user/getUserSettings'])

const props = defineProps<{
  invites: Invite[]
}>()

const urlFromUuid = (uuid: string) => {
  return `${location.protocol}//${window.location.host}/invite/${uuid}`
}

const columns: ColumnDef<Invite>[] = [
  {
    accessorKey: 'fullname',
    header: ({column}) => {
      return h(Button, {
        variant: 'ghost',
        size: 'sm',
        onClick: () => column.toggleSorting(column.getIsSorted() === 'asc'),
      }, () => ['Name', h(ArrowUpDown, {class: 'ml-1 h-3 w-3'})])
    },
  },
  // {
  //   accessorKey: 'email',
  //   header: ({column}) => {
  //     return h(Button, {
  //       variant: 'ghost',
  //       size: 'sm',
  //       onClick: () => column.toggleSorting(column.getIsSorted() === 'asc'),
  //     }, () => ['Email', h(ArrowUpDown, {class: 'ml-1 h-3 w-3'})])
  //   },
  //   cell: ({row}) => h('div', {class: 'lowercase'}, row.getValue('email')),
  // },
  {
    accessorKey: 'project_role',
    header: 'Role',
    cell: ({row}) => {
      return h('div', {class: 'capitalize text-gray-500'}, row.getValue('project_role'))
    },
  },
  {
    accessorKey: 'created_at',
    header: 'Created',
    cell: ({row}) => {
      const date = formatTime(row.getValue('created_at'), settings.value.tz, 'D MMMM YYYY')
      return h('div', {class: 'text-gray-500 whitespace-nowrap'}, date)
    },
  },
  {
    accessorKey: 'uuid',
    header: 'Invite link',
    cell: ({row}) => {
      return h('div', {class: 'w-[125px]'}, [
        h(CopyButton, {url: urlFromUuid(row.getValue('uuid'))}),
      ])
    },
  },
]
</script>

<template>
  <DataTable :columns="columns" :data="invites" />
</template>
