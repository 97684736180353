<script setup>
import { ref, onBeforeMount, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import {API, HOST} from '@/utils/http'

import { Button } from '@/components/ui/button'

const router = useRouter()
const route = useRoute()
const inviteCode = route.params.inviteCode

const store = useStore()
const isLoggedIn = computed(() => store.getters.getIsLoggedIn)

const invite = ref(null)

const joinProject = async () => {
  // save invite code to local storage and use it after login in fetchTokens
  // can be refactored if separate token auth and invite code auth at client and backend
  if (!isLoggedIn.value) {
    localStorage.setItem('inviteCode', inviteCode)
    router.push({ name: 'AuthLogin' })
  } else {
    const response = await API({
      method: 'PUT',
      url: `${HOST}/invite/${inviteCode}`
    })
    store.dispatch('project/fetchProjects')
    router.push({ name: 'Dashboard' })
  }
}

const close = () => {
  router.push({ name: 'AuthLogin' })
}

onBeforeMount(async () => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST}/invite/${inviteCode}`
    })

    invite.value = response.data ? response.data : null
  } catch (error) {
    console.error(error)
  }
})
</script>

<template>
  <div class="md:p-1">
    <div class="flex items-center">
      <div class="basis-2/3">
        <img src="@/assets/img/earth-art.png" alt="Earth" class="md:max-w-full">
      </div>
      <div class="basis-1/3 flex flex-col items-center justify-center ml-4">
        <div v-if="invite" class="mb-4 text-left">
          <h1 class="font-black text-xl md:text-3xl">Welcome to Earth</h1>
          <div v-if="invite" class="mt-4">
            <h3 class="mb-2">Hey, {{ invite.fullname }}</h3>
            <p>
              You are invited to join <br> the <b>{{ invite.project_name }}</b> project.
            </p>
          </div>
          <Button size="lg" class="mt-6 font-semibold" @click="joinProject">Join now</Button>
        </div>
        <div v-else class="text-center">
          <p class="mt-4 mb-3">Invite expired or not exists</p>
          <Button @click="close">Close</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
</style>
