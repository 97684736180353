export const state = {
  current: null,
  lastSelected: null,
  projects: [],
  flatedProjects: [],
  selected: [],

  currentTags: [],
  allTags: [],

  components: null,
  allComponents: null,

  invites: [],
  allInvites: [],

  currentMember: {},
  memberInvites: [],
  members: [],
  allMembers: [],
  membersCount: 0,

  countries: [],
  currentRegion: null,
  groups: [],

  planetFeatures: null,

  showProjectForm: false,
  projectFormContext: null,
}
