import { firebase, router } from '@/router'

export const isLoading = ({commit}, payload) => {
  commit('IS_LOADING', payload)
}

export const showSnackbar = ({ commit }, payload) => {
  commit('SHOW_SNACKBAR', payload)
}

export const hideSnackbar = ({ commit }) => {
  commit('HIDE_SNACKBAR')
}

export const setLoaderSize = ({ commit }, size) => {
  commit('SET_LOADER_SIZE', size)
}

export const setLoaderColor = ({commit}, color) => {
  commit('SET_LOADER_COLOR', color)
}

export const setLoaderType = ({commit}, type) => {
  commit('SET_LOADER_TYPE', type)
}

export const logout = async ({ commit, dispatch }) => {
  firebase.auth.signOut()

  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('selectedOrg')
  localStorage.removeItem('selectedProject')
  localStorage.removeItem('selectedRegion')

  commit('API_AUTH', false)

  commit('org/SET_SELECTED_ORG', null)
  commit('project/SET_PROJECTS', [])
  commit('user/SET_USER', null)
  commit('user/SET_MEMBER', null)
  commit('SET_IS_LOGGEDIN', false)
  dispatch('isLoading', false)

  if (window.location.pathname.includes('/invite')) {
    return
  }
  router.push('/auth/login').catch(()=>{})
}
