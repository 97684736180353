<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import { InviteItem } from '@/components/project-list'

const store = useStore()
const invites = computed(() => store.getters['project/getAllInvites'])
</script>

<template>
  <div v-if="invites.length">
    <div class="px-3 mb-3">
      <h3 class="font-bold">Invites ({{invites.length}})</h3>
      <div class="mt-1" :key="invites.length">
        <InviteItem
          v-for="invite in invites"
          :key="invite.uuid"
          :invite="invite"
        />
      </div>
    </div>
    <hr class="mb-3">
  </div>
</template>
