import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocation,
  NavigationGuard,
  RouteRecordRaw,
  RouteLocationNormalizedGeneric
} from 'vue-router'

import { onAuthStateChanged, getAuth } from 'firebase/auth'
import firebase from 'firebase/compat/app'

import AuthLayout from '@/pages/Auth/layout.vue'
import AuthLogin from '@/pages/Auth/Login.vue'
import AuthSignUp from '@/pages/Auth/SignUp.vue'

import Invite from '@/pages/Invite/index.vue'

import Profile from '@/pages/User/index.vue'
import Dashboard from '@/pages/Dashboard/index.vue'

import FeatureLayout from '@/pages/Feature/layout.vue'
import FeatureIndex from '@/pages/Feature/index.vue'
import FeatureEdit from '@/pages/Feature/Edit/index.vue'

import OrgLayout from '@/pages/Organization/layout.vue'
import OrgMembers from '@/pages/Organization/Members/index.vue'
import OrgSettings from '@/pages/Organization/Settings/index.vue'
import ProjectLayout from '@/pages/Project/layout.vue'
import ProjectHome from '@/pages/Project/index.vue'
import ProjectSettings from '@/pages/Project/Settings/index.vue'
import ProjectTemplates from '@/pages/Project/Templates/index.vue'
import ProjectMembers from '@/pages/Project/Members/index.vue'
import ProjectComponents from '@/pages/Project/Components/index.vue'
import ProjectEdit from '@/pages/Project/Edit/index.vue'
import ProjectCreate from '@/pages/Project/Create/index.vue'

import LayersLayout from '@/pages/Layers/layout.vue'
import LayersCreate from '@/pages/Layers/Create/index.vue'

import Admin from '@/pages/Admin/index.vue'

import FileViewer from "@/pages/FileViewer/index.vue"

const nextPreserveQueryParams: NavigationGuard = (
  to: RouteLocationNormalizedGeneric,
  from: RouteLocationNormalizedGeneric,
  next: NavigationGuardNext
) => {
  const isRedirectionNeeded = Object.keys(from.query).length > 0 && JSON.stringify(to.query) !== JSON.stringify(from.query)
  console.log('isRedirectionNeeded', isRedirectionNeeded)
  if (isRedirectionNeeded) {
    next({ path: to.path, query: from.query })
  } else {
    next()
  }
}

const requireAuth: NavigationGuard = (
  to: RouteLocationNormalizedGeneric,
  from: RouteLocationNormalizedGeneric,
  next: NavigationGuardNext
) => {
  onAuthStateChanged(getAuth(), (user: any | null) => {
    if (!user) {
      console.log('onAuthStateChanged', 'User not logged in')
      // next({
      //   path: '/auth/login',
      //   query: from.query
      // })
    } else {
      console.log('onAuthStateChanged', 'User logged in')
      nextPreserveQueryParams(to, from, next)
    }
  })
}

const logout: NavigationGuard = (
  to: RouteLocation,
  from: RouteLocation,
  next: NavigationGuardNext
) => {
  firebase.auth().signOut().then(() => {
    next()
  })
}

const rts: RouteRecordRaw[] = [
  {
    path: '/auth',
    name: 'Auth',
    component: AuthLayout,
    meta: {
      layout: 'modal'
    },
    children: [
      {
        path: 'login',
        name: 'AuthLogin',
        component: AuthLogin,
        meta: {
          title: 'Login',
          layout: 'modal'
        }
      },
      {
        path: 'sign-up',
        name: 'AuthSignUp',
        component: AuthSignUp,
        meta: {
          title: 'Sign Up',
          layout: 'modal'
        }
      },
    ]
  },
  {
    path: '/invite/:inviteCode',
    name: 'Invite',
    component: Invite,
    meta: {
      title: 'Invite',
      layout: 'modal'
    },
  },
  // {
  //   path: '/logout',
  //   name: 'Logout',
  //   redirect: {name: 'AuthLogin'},
  //   beforeEnter: logout
  // },
  {
    path: '/user',
    name: 'User',
    component: Profile,
    beforeEnter: requireAuth,
    meta: {
      title: 'Profile Settings',
    },
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: requireAuth,
    meta: {
      title: 'Dashboard',
      layout: 'hide'
    }
  },
  {
    path: '/projects/:project_id/features/:featureCode',
    name: 'Feature',
    component: FeatureLayout,
    redirect: {name: 'FeatureIndex'},
    beforeEnter: requireAuth,
    children: [
      {
        path: '',
        name: 'FeatureIndex',
        component: FeatureIndex,
        meta: {
          title: 'Feature',
        }
      },
      {
        path: 'edit',
        name: 'FeatureEdit',
        component: FeatureEdit,
        meta: {
          title: 'Edit Feature',
        }
      }
    ]
  },
  {
    path: '/file-viewer/',
    name: 'FileViewer',
    component: FileViewer,
    beforeEnter: requireAuth,
    meta: {
      title: 'Import Data',
    }
  },
  // {
  //   path: '/organizations',
  //   name: 'Organizations',
  //   component: Organizations,
  //   beforeEnter: requireAuth,
  //   meta: {
  //     layout: 'float-big'
  //   }
  // },
  {
    path: '/organizations/:org_id',
    name: 'Organization',
    component: OrgLayout,
    redirect: {name: 'OrgMembers'},
    beforeEnter: requireAuth,
    meta: {
      layout: 'float-big'
    },
    children: [
      {
        path: 'members',
        name: 'OrgMembers',
        component: OrgMembers,
        meta: {
          title: 'Organization Members',
          layout: 'float-big'
        }
      },
      // {
      //   path: 'billing',
      //   name: 'OrgBilling',
      //   component: OrgBilling,
      //   meta: {
      //     layout: 'float-big'
      //   },
      //   beforeEnter: requireAuth,
      // },
      {
        path: 'settings',
        name: 'OrgSettings',
        component: OrgSettings,
        meta: {
          title: 'Organization Settings',
          layout: 'float-big'
        }
      },
    ]
  },
  {
    path: '/layers/',
    name: 'Layers',
    component: LayersLayout,
    redirect: {name: 'LayersCreate'},
    beforeEnter: requireAuth,
    children: [
      {
        path: 'create',
        name: 'LayersCreate',
        component: LayersCreate,
        meta: {
          title: 'New Layer',
        }
      },
    ]
  },
  {
    path: '/projects/:project_id',
    name: 'Project',
    component: ProjectLayout,
    redirect: {name: 'ProjectHome'},
    beforeEnter: requireAuth,
    children: [
      {
        path: '',
        name: 'ProjectHome',
        component: ProjectHome,
        meta: {
          title: 'Project Data',
        }
      },
      {
        path: 'edit',
        name: 'ProjectEdit',
        component: ProjectEdit,
        meta: {
          title: 'Edit Project',
        }
      },
      {
        path: 'create',
        name: 'ProjectCreate',
        component: ProjectCreate,
        meta: {
          title: 'New Project',
        }
      },
      {
        path: 'components',
        name: 'ProjectComponents',
        component: ProjectComponents,
        meta: {
          layout: 'float-big',
          title: 'Project Components',
        }
      },
      {
        path: 'settings',
        name: 'ProjectSettings',
        component: ProjectSettings,
        meta: {
          layout: 'float-big',
          title: 'Project Settings',
        }
      },
      {
        path: 'templates',
        name: 'ProjectTemplates',
        component: ProjectTemplates,
        meta: {
          layout: 'float-big',
          title: 'Project Templates',
        }
      },
      {
        path: 'members',
        name: 'ProjectMembers',
        component: ProjectMembers,
        meta: {
          layout: 'float-big',
          title: 'Project Members',
        }
      }
    ]
  },
  {
    path: '/admin/',
    name: 'Admin',
    component: Admin,
    beforeEnter: requireAuth,
    meta: {
      title: 'Admin Tools',
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
    beforeEnter: requireAuth
  }
]

const baseUrl = window.location.pathname + window.location.search
let history = null
if (window.location.search.includes('oobCode')) {
  history = createWebHistory(baseUrl)
} else {
  history = createWebHistory()
}

export const router = createRouter({
  history: history,
  routes: rts
})

export default router
