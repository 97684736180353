<script setup lang="ts">
import { h, computed } from 'vue'
import { useStore } from 'vuex'
import type { ColumnDef } from '@tanstack/vue-table'
import {formatTime} from '@/utils/datetime.js'

import { ArrowUpDown, ChevronDown } from 'lucide-vue-next'
import { Button } from '@/components/ui/button'
import { DataTable } from '@/components/ui/data-table'
import { MemberAvatar } from '@/components/ui/avatar'

import MembersListDropdown from './MembersListDropdown.vue'

import type { Member } from '@/types'

const store = useStore()
const settings = computed(() => store.getters['user/getUserSettings'])

const roleToName = {
  project_admin: 'Admin',
  project_editor: 'Editor',
  project_viewer: 'Viewer',
}

const props = defineProps<{
  members: Member[]
}>()

const columns: ColumnDef<Member>[] = [
  {
    accessorKey: 'display_name',
    header: ({column}) => {
      return h(Button, {
        variant: 'ghost',
        size: 'sm',
        onClick: () => column.toggleSorting(column.getIsSorted() === 'asc'),
      }, () => ['Name', h(ArrowUpDown, {class: 'ml-1 h-3 w-3'})])
    },
    cell: ({row}) => {
      const member = row.original
      return h(MemberAvatar, {avatar_url: member.avatar_url, display_name: member.display_name})
    },
  },
  {
    accessorKey: 'email',
    header: ({column}) => {
      return h(Button, {
        variant: 'ghost',
        size: 'sm',
        onClick: () => column.toggleSorting(column.getIsSorted() === 'asc'),
      }, () => ['Email', h(ArrowUpDown, {class: 'ml-1 h-3 w-3'})])
    },
    cell: ({row}) => h('div', {class: 'lowercase'}, row.getValue('email')),
  },
  {
    accessorKey: 'role_name',
    header: 'Role',
    cell: ({row}) => {
      // @ts-ignore
      return h('div', {class: 'capitalize text-gray-500'}, roleToName[row.getValue('role_name')])
    },
  },
  // {
  //   accessorKey: 'phone',
  //   header: 'Phone',
  //   cell: ({row}) => {
  //     // @ts-ignore
  //     return h('div', {class: 'capitalize text-gray-500'}, row.getValue('phone'))
  //   },
  // },
  {
    accessorKey: 'create_dttm',
    header: 'Member since',
    cell: ({row}) => {
      const date = formatTime(row.getValue('create_dttm'), settings.value.tz, 'D MMMM YYYY')
      return h('div', {class: 'text-gray-500 whitespace-nowrap'}, date)
    },
  },
  {
    id: 'actions',
    enableHiding: false,
    cell: ({ row }) => {
      const member = row.original
      return h('div', { class: 'relative' }, h(MembersListDropdown, {
        member,
      }))
    },
  }
]
</script>

<template>
  <DataTable :columns="columns" :data="members" />
</template>
