import countryJson from "@/assets/countries.json"

export const mutations = {
  SET_PROJECTS(state, projects) {
    state.projects = projects
  },
  SET_FLATED_PROJECTS(state, projects) {
    state.flatedProjects = projects
  },
  DELETE_PROJECT(state, project) {
    state.projects = state.projects.filter(p => p.project_id !== project.project_id)
    state.flatedProjects = state.flatedProjects.filter(p => p.project_id !== project.project_id)
  },
  ADD_PROJECT(state, project) {
    state.projects.push(project)
    state.flatedProjects.push(project)
  },
  SET_CURRENT(state, payload) {
    state.current = payload
    localStorage.setItem('selectedProject', JSON.stringify(payload))
  },
  SET_LAST_SELECTED(state, payload) {
    state.lastSelected = payload
  },
  RESET_PROJECTS(state) {
    state.projects = []
  },

  ADD_SELECTED(state, payload) {
    state.selected.push(payload)
  },
  REMOVE_SELECTED (state, payload) {
    state.selected = state.selected.filter(p => p.project_id !== payload.project_id)
  },

  SET_CURRENT_MEMBER(state, payload) {
    state.currentMember = payload
  },
  SET_MEMBERS_COUNT(state, payload) {
    state.membersCount = payload
  },

  SET_COMPONENTS(state, payload) {
    state.components = payload.map((component) => {
      const transformedName = component.name
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join('')
      return { ...component, componentName: transformedName }
    })
  },
  SET_ALL_COMPONENTS (state, payload) {
    state.allComponents = payload
  },

  SET_COUNTRIES(state, payload) {
    const countriesCount = payload.reduce((acc, country) => {
      if (acc[country]) {
        acc[country]++
      } else {
        acc[country] = 1
      }
      return acc
    }, {})

    const countries = Object.entries(countriesCount).map(
      ([country, count]) => (
        { country: country,
          countryFullName: country === 'null' ? 'Projects without countries' : countryJson[country],
          count
        })
    )

    countries.sort((a,b) => {
      if (a.country === 'null') return -1
      if (b.country === 'null') return 1
      if (a.countryFullName < b.countryFullName) return -1
      if (a.countryFullName > b.countryFullName) return 1
      return 0
    })

    state.countries = countries
  },
  SET_CURRENT_REGION(state, payload) {
    state.currentRegion = payload
  },

  ADD_GROUP(state, payload) {
    state.groups.push(payload)
  },

  // Project Members
  SET_ALL_MEMBERS(state, payload) {
    state.allMembers = payload
  },
  SET_MEMBERS(state, payload) {
    state.members = payload
  },
  UPDATE_MEMBER(state, payload) {
    state.members = state.members.map(m => {
      return m.member_id === payload.member_id ? payload : m
    })
  },
  REMOVE_MEMBER(state, memberId) {
    state.members = state.members.filter(m => m.project_member_id !== memberId)
  },

  // Project Tags
  SET_ALL_TAGS(state, payload) {
    state.allTags = payload
  },
  SET_PROJECT_TAGS(state, payload) {
    state.currentTags = payload
  },
  RESET_PROJECT_TAGS(state, payload) {
    state.currentTags = []
  },
  ADD_TAG(state, payload) {
    state.currentTags.push(payload)
  },
  REMOVE_TAG(state, tagID) {
    state.currentTags = state.currentTags.filter(t => t.tag_id !== tagID)
  },
  EDIT_TAG(state, payload) {
    state.currentTags = state.currentTags.map(t => {
      return t.tag_id === payload.tag_id ? payload : t
    })
  },

  // Invites
  SET_MEMBER_INVITES(state, payload) {
    state.memberInvites = payload
  },
  REMOVE_MEMBER_INVITE(state, payload) {
    state.memberInvites = state.memberInvites.filter(i => i.id !== payload)
  },

  SET_INVITES(state, payload) {
    state.invites = payload
  },
  SET_ALL_INVITES(state, payload) {
    state.allInvites = payload
  },
  REMOVE_MEMBER_INVITE(state, payload) {
    state.allInvites = state.allInvites.filter(i => i.uuid !== payload)
  },
  REMOVE_INVITE(state, payload) {
    state.invites = state.invites.filter(i => i.id !== payload)
  },
  ADD_INVITE(state, invite) {
    state.invites.push(invite)
  },

  // Planet
  SET_PLANET_FEATURES(state, payload) {
    state.planetFeatures = payload
  },

  SET_SHOW_PROJECT_FORM(state, payload) {
    state.showProjectForm = payload
  },
  SET_PROJECT_FORM_CONTEXT(state, payload) {
    state.projectFormContext = payload
  }
}
