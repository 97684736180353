<script setup>
import { ref } from 'vue'
import { Link } from 'lucide-vue-next'
import { Button } from '@/components/ui/button'

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
})

const isCopied = ref(false)

const copyLink = (url) => {
  navigator.clipboard.writeText(url)
  isCopied.value = true
  setTimeout(() => {
    isCopied.value = false
  }, 1500)
}
</script>

<template>
  <Button
    variant="secondary"
    size="sm"
    @click="copyLink(url)"
  >
    <Link class="h-4 w-4 mr-1" />
    <span v-if="!isCopied">Copy link</span>
    <span v-else>Link copied!</span>
  </Button>
</template>
