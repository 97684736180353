<script setup>
import {computed, ref, onMounted} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'

import {ProjectListItem} from '@/components/project-list'
import {Button} from '@/components/ui/button'
import {Settings} from 'lucide-vue-next'

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

const store = useStore()
const orgs = computed(() => store.getters['org/getOrgs'])
const project = computed(() => store.getters['project/current'])
const projects = computed(() => store.getters['project/getProjects'])

const sortByChooses = [
  { value: 'az', label: 'A-Z' },
  { value: 'za', label: 'Z-A' },
  { value: 'created_at', label: 'Created At' },
  { value: 'last_activity', label: 'Last Activity' },
]
const sortBy = ref(sortByChooses[0].value)

const projectsByOrgs = computed(() => {
  const sortProjects = (projectsArray) => {
    return projectsArray.map(project => {
      if (project.children && Array.isArray(project.children)) {
        project.children = sortProjects(project.children);
      }
      return project;
    }).sort((a, b) => {
      if (sortBy.value === 'az') {
        return a.project_name.localeCompare(b.project_name)
      } else if (sortBy.value === 'za') {
        return b.project_name.localeCompare(a.project_name)
      } else if (sortBy.value === 'created_at') {
        return new Date(a.created_at) - new Date(b.created_at);
      } else if (sortBy.value === 'last_activity') {
        return new Date(b.last_activity) - new Date(a.last_activity);
      }
      return 0;
    });
  };

  const sortedProjects = orgs.value.map(org => {
    const orgProjects = projects.value
      .filter(i => i.organization_id === org.organization_id);

    return { org, orgProjects: sortProjects(orgProjects) };
  }).filter(({ orgProjects }) => orgProjects.length > 0);

  return sortedProjects;
});

const openedProjectIds = ref(localStorage.getItem('openedProjectIds') ? JSON.parse(localStorage.getItem('openedProjectIds')) : {})

const select = (project, level) => {
  if (!!openedProjectIds.value?.[project.project_id]) {
    delete openedProjectIds.value[project.project_id]
  } else {
    openedProjectIds.value[project.project_id] = {
      level: level,
      project: project
    }
  }

  localStorage.setItem('openedProjectIds', JSON.stringify(openedProjectIds.value))
}
</script>

<template>
  <div class="px-2 flex items-center justify-end">
    <div class="flex items-center">
      <span class="mr-2 text-xs text-gray-500">Sort By:</span>
      <Select v-model="sortBy">
        <SelectTrigger class="flex-1 max-w-[150px] border-0 p-0 h-auto text-xs">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem v-for="item in sortByChooses" :value="item.value" :key="item.value">
              {{ item.label }}
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  </div>

  <div v-for="org in projectsByOrgs" class="border-b border-gray-200 pb-1">
    <div class="ml-3">
      <div class="inline-flex items-center">
        <h3 class="font-bold mr-1">{{ org.org.organization_name }}</h3>
        <router-link :to="{ name: 'OrgMembers', params: { org_id: org.org.organization_id }}">
          <Button class="rounded-full w-7 h-7 p-0" variant="ghost" size="sm">
            <Settings class="w-4 h-4 text-gray-500" />
          </Button>
        </router-link>
      </div>
    </div>
    <ProjectListItem
      v-for="child in org.orgProjects"
      :key="child.project_id"
      :project="child"
      :opened-project-ids="openedProjectIds"
      @select="(project, level) => select(project, level + 1)"
    />
  </div>
</template>
