<script setup lang="ts">
import {computed, ref, PropType} from 'vue'
import {useRoute} from 'vue-router'
import {useStore} from 'vuex'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'

import type { Member } from '@/types'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/toast'
import {Switch} from '@/components/ui/switch'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { Label } from '@/components/ui/label'

const store = useStore()
const route = useRoute()

const currentMember = computed(() => store.getters['user/getMember'])
const projectId = route.params.project_id

const isOpen = ref(false)

const props = defineProps({
  member: {
    type: Object as PropType<Member>,
    default: null
  },
  editMode: {
    type: Boolean as PropType<boolean>,
    default: false
  },
})

const isEditMode = computed(() => props.editMode && props.member)

const open = () => {
  isOpen.value = true
}

const close = () => {
  isOpen.value = false
}

const formSchema = toTypedSchema(z.object({
  name: z.string().min(3).max(255).default(isEditMode.value ? props.member.display_name : ''),
  email: z.string().email().default(isEditMode.value ? props.member.email : ''),
  role_name: z.string().default(isEditMode.value ? props.member.role_name : 'project_editor'),
  manage_members: z.boolean().default(false),
}))

const onSubmit = async (values: any) => {
  if (isEditMode.value) {
    await store.dispatch('project/updateMember', {
      member_id: props.member.member_id,
      project_id: projectId,
      ...values
    })
    toast({title: `Member ${values.name} updated`,})
  } else {
    await store.dispatch('project/addInvite', {
      member_id: currentMember.value.member_id,
      project_id: projectId,
      ...values
    })
    toast({title: `Invite sent to ${values.email}`,})
  }


  close()
}

defineExpose({
  open
})
</script>

<template>
  <Dialog :open="isOpen" @update:open="isOpen = $event">
    <DialogContent class="max-w-[350px]">
      <DialogHeader>
        <DialogTitle v-if="editMode">Edit member</DialogTitle>
        <DialogTitle v-else>Add member</DialogTitle>
        <DialogDescription></DialogDescription>
      </DialogHeader>

      <Form class="space-y-3" :validation-schema="formSchema" @submit="onSubmit">
        <FormField v-slot="{ componentField }" name="name">
          <FormItem>
            <FormLabel>Name</FormLabel>
            <FormControl>
              <Input v-bind="componentField" :disabled="editMode" />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="email">
          <FormItem>
            <FormLabel>Email</FormLabel>
            <FormControl>
              <Input v-bind="componentField" :disabled="editMode" />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="role_name">
          <FormItem>
            <FormControl>
              <RadioGroup v-bind="componentField">
                <FormItem class="flex items-center space-y-0 gap-x-3">
                  <FormControl>
                    <RadioGroupItem value="project_admin" />
                  </FormControl>
                  <FormLabel class="font-normal">Administator</FormLabel>
                </FormItem>
                <FormItem class="flex items-center space-y-0 gap-x-3">
                  <FormControl>
                    <RadioGroupItem value="project_viewer" />
                  </FormControl>
                  <FormLabel class="font-normal">View</FormLabel>
                </FormItem>
                <FormItem class="flex items-center space-y-0 gap-x-3">
                  <FormControl>
                    <RadioGroupItem value="project_editor" />
                  </FormControl>
                  <FormLabel class="font-normal">Edit</FormLabel>
                </FormItem>
              </RadioGroup>
            </FormControl>
          </FormItem>
          <FormMessage />
        </FormField>

        <DialogFooter>
          <Button type="button" variant="ghost" @click="close">Close</Button>
          <Button type="submit" v-if="editMode">Save changes</Button>
          <Button type="submit" v-else>Invite</Button>
        </DialogFooter>
      </Form>
    </DialogContent>
  </Dialog>
</template>
