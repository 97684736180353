export const getters = {
  getProjects: state => {
    return state.projects
  },
  getFlatedProjects: state => {
    return state.flatedProjects
  },
  current: state => {
    return state.current
  },
  getLastSelected: state => {
    return state.lastSelected
  },
  getSelected: state => {
    return state.selected
  },

  // Components
  getComponents: state => {
    return state.components
  },
  getAllComponents: state => {
    return state.allComponents
  },

  // Project Invites
  getMemberInvites: state => {
    return state.memberInvites
  },
  getInvites: state => {
    return state.invites
  },
  getAllInvites: state => {
    return state.allInvites
  },

  // Project members
  getCurrentMember: state => {
    return state.currentMember
  },
  getMembers: state => {
    return state.members
  },
  getAllMembers: state => {
    return state.allMembers
  },
  getMembersCount: state => {
    return state.membersCount
  },

  // Tags
  getCurrentTags: state => {
    return state.currentTags
  },

  getAllTags: state => {
    return state.allTags
  },

  // Countries
  getGroups: state => {
    return state.groups
  },
  getProjectsCountries: state => {
    return state.countries
  },
  getCurrentRegion: state => {
    return state.currentRegion
  },

  // Planet
  getPlanetFeatures: state => {
    return state.planetFeatures
  },

  getShowProjectForm: state => {
    return state.showProjectForm
  },
  getProjectFormContext: state => {
    return state.projectFormContext
  }
}
