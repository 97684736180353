<script setup lang="ts">
import {computed, ref, watch} from 'vue'
import {useRoute} from 'vue-router'
import {useStore} from 'vuex'
import { toTypedSchema } from '@vee-validate/zod'
import parsePhoneNumber from 'libphonenumber-js'
import * as z from 'zod'
import { Link, UserRoundPlus } from 'lucide-vue-next'

import { Invite } from '@/types'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTrigger,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { Spinner } from '@/components/ui/spinner'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'

const store = useStore()
const route = useRoute()

const currentMember = computed(() => store.getters['user/getMember'])
const projectId = route.params.project_id

const isOpen = ref(false)
const isInviteInProgress = ref(false)
const isLinkCopied = ref(false)
const invite = ref<Invite | null>(null)
const inviteLink = computed(() => invite.value ? urlFromUuid(invite.value.uuid) : null)

const countryCode = ref<string | null | undefined>(null)
const countryCodeToEmoji = computed(() => {
  if (!countryCode.value) return null

  const code = countryCode.value.toUpperCase()
  const codePoints = code
    .split('')
    .map(char => char.charCodeAt(0) + 127397)

  return String.fromCodePoint(...codePoints)
})

const formSchema = toTypedSchema(z.object({
  name: z.string().min(3).max(255),
  email: z.string().email().optional().nullable(),
  phone: z.string().optional().nullable().transform((value, ctx) => {
    if (!value) return null
    const phoneNumber = parsePhoneNumber(value, {
      defaultCountry: 'US',
    })

    if (!phoneNumber?.isValid()) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Invalid or not full phone number",
      })
      countryCode.value = null
      return z.NEVER
    }

    countryCode.value = phoneNumber.country

    return phoneNumber.number
  }),
  role_name: z.string().default('project_editor'),
  manage_members: z.boolean().default(false),
}))

const close = () => {
  isOpen.value = false
}

const copyLink = () => {
  if (!inviteLink.value) return
  navigator.clipboard.writeText(inviteLink.value)
  isLinkCopied.value = true
  setTimeout(() => {
    isLinkCopied.value = false
  }, 1000)
}

const urlFromUuid = (uuid: string) => {
  return `${location.protocol}//${window.location.host}/invite/${uuid}`
}

const onSubmit = async (values: any) => {
  isInviteInProgress.value = true
  invite.value = await store.dispatch('project/addInvite', {
    inviter_id: currentMember.value.member_id,
    project_id: projectId,
    ...values
  })
  isInviteInProgress.value = false
}

watch(() => isOpen.value, (isOpen) => {
  if (!isOpen) {
    isInviteInProgress.value = false
    invite.value = null
  }
})
</script>

<template>
  <Dialog :open="isOpen" @update:open="isOpen = $event">
    <DialogTrigger as-child>
      <slot>
        <Button variant="ghost" class="h-8 px-2 flex justify-start">
          <UserRoundPlus class="w-5 h-5 text-gray-600" />
          <!-- Add Member -->
        </Button>
      </slot>
    </DialogTrigger>
    <DialogContent class="max-w-[350px]">
      <DialogHeader v-if="!invite">
        <DialogTitle>Invite a Member</DialogTitle>
        <DialogDescription>
          Create a link to invite a member to a project
        </DialogDescription>
      </DialogHeader>

      <div v-if="invite" class="text-center pt-3">
        <div class="mb-5">
          <h3 class="font-bold text-lg">Invite Link Created</h3>
          <p class="text-sm">Copy and share the link with the person.</p>

          <div v-if="invite.email" class="mt-3">
            <p class="text-sm">A link will also be sent to <span class="font-semibold">{{ invite.email }}</span></p>
          </div>
        </div>
        <div v-if="inviteLink">
          <Input :model-value="inviteLink" readonly @click="copyLink" class="cursor-pointer" />
          <div class="mt-2 flex justify-center">
            <Button variant="secondary" class="flex items-center" @click="copyLink">
              <Link class="w-3 h-3 mr-1" />
              <span v-if="!isLinkCopied">Copy link</span>
              <span v-else>Link copied!</span>
            </Button>
          </div>
        </div>
      </div>
      <Form v-else class="space-y-3" :validation-schema="formSchema" @submit="onSubmit">
        <FormField v-slot="{ componentField }" name="name">
          <FormItem>
            <FormLabel>Name</FormLabel>
            <FormControl>
              <Input v-bind="componentField" placeholder="" />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <!-- <FormField v-slot="{ componentField }" name="phone">
          <FormItem>
            <FormLabel>Phone {{ countryCodeToEmoji }}</FormLabel>
            <FormControl>
              <Input v-bind="componentField" />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField> -->

        <FormField v-slot="{ componentField }" name="role_name">
          <FormItem>
            <FormLabel>Access</FormLabel>
            <FormControl>
              <RadioGroup v-bind="componentField">
                <FormItem class="flex items-center space-y-0 gap-x-3">
                  <FormControl>
                    <RadioGroupItem value="project_admin" />
                  </FormControl>
                  <FormLabel class="font-normal">Administator</FormLabel>
                </FormItem>
                <FormItem class="flex items-center space-y-0 gap-x-3">
                  <FormControl>
                    <RadioGroupItem value="project_editor" />
                  </FormControl>
                  <FormLabel class="font-normal">Edit</FormLabel>
                </FormItem>
                <FormItem class="flex items-center space-y-0 gap-x-3">
                  <FormControl>
                    <RadioGroupItem value="project_viewer" />
                  </FormControl>
                  <FormLabel class="font-normal">View</FormLabel>
                </FormItem>
              </RadioGroup>
            </FormControl>
          </FormItem>
          <FormMessage />
        </FormField>

        <hr>

        <FormField v-slot="{ componentField }" name="email">
          <FormItem>
            <FormLabel>Send Link via Email <span class="text-gray-400">(Optional)</span></FormLabel>
            <FormControl>
              <Input v-bind="componentField" placeholder="example@gmail.com" />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <DialogFooter>
          <Button type="button" variant="ghost" @click="close">Close</Button>
          <Button type="submit" :disabled="isInviteInProgress">
            <Spinner v-if="isInviteInProgress" class="w-4 h-4" />
            <span v-else>Create</span>
          </Button>
        </DialogFooter>
      </Form>
    </DialogContent>
  </Dialog>
</template>
