<script setup>
import { useStore } from 'vuex'
import { Button } from '@/components/ui/button'

const store = useStore()
const props = defineProps({
  invite: {
    type: Object,
    required: true
  }
})

const acceptInvite = async () => {
  await store.dispatch('project/acceptInvite', props.invite)
  await store.dispatch('org/fetchOrgs')
  await store.dispatch('project/fetchProjects')
}
</script>

<template>
  <div class="flex items-center justify-between bg-white rounded-lg mb-2">
    <div class="flex items-center">
      <div>
        <h4>{{invite.project_name}}</h4>
        <p class="text-gray-500">Invited by {{invite.inviter_name}}</p>
      </div>
    </div>
    <div class="flex items-center">
      <Button
        class="mr-2"
        variant="secondary"
        size="sm"
        @click="acceptInvite"
      >Accept</Button>
    </div>
  </div>
</template>
